import React from 'react'


const Changes = () => {
  return (
    <section>

        <div className="chanes-box">
            
        </div>
    </section>
  )
}

export default Changes